<template>
  <v-toolbar flat>
    <v-spacer></v-spacer>
    <v-btn depressed class="mx-1" @click="onReset"
      ><v-icon left>mdi-sync</v-icon>{{ $t('actions.refresh') }}</v-btn
    >
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="info" depressed v-bind="attrs" v-on="on">
          <v-icon left>mdi-filter-variant</v-icon>
          {{ $tc('actions.filter', 2) }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title> </v-card-title>

        <v-card-text>
          <BaseSelector
            v-model="filter.name"
            :items="inventoryCategories"
            :item-text="
              item => item && $t(`inventory.categories.${item.value}`)
            "
            item-value="value"
            :placeholder="$tc('item.name', 1)"
          />
          <BaseSelector
            v-model="filter.itemGroup"
            :items="itemGroups"
            item-text="attributes.description"
            item-value="id"
            :placeholder="$tc('lot.name', 1)"
          />
          <BaseSelector
            v-model="filter.specie"
            :items="species"
            :item-text="item => item && $t(`species.${item.text}`)"
            item-value="value"
            :placeholder="$t('species.Sapele')"
          />
          <BaseSelector
            v-model="filter.woodCategory"
            :items="categories"
            :item-text="item => $t(`item.${item}`)"
            :placeholder="$t('item.FSC 100%')"
          />
          <BaseSelector
            v-model="filter.status"
            :items="[
              { text: 'in_stock', value: 'in_stock' },
              { text: 'all', value: '' }
            ]"
            :item-text="item => item && $t(`inventory.${item.text}`)"
            item-value="value"
            :placeholder="$t('inventory.in_stock')"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="grey lighten-1"
            light
            outlined
            @click="onReset"
            >{{ $t('actions.cancel') }}</v-btn
          >
          <v-btn depressed color="primary" @click="filterItems(filter)">{{
            $t('actions.save')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-toolbar>
</template>
<script>
import { SPECIES, CATEGORIES, INVENTORY_CATEGORIES } from '@/common/terms'
import { ORGANIZATION_ID } from '@/common/config'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      status: 'in_stock',
      dialog: false,
      species: SPECIES,
      categories: CATEGORIES,
      inventoryCategories: INVENTORY_CATEGORIES,
      itemGroups: [],
      filter: this.createFilterObj(),
      filterOn: false
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions({ filterItemGroupLot: 'itemGroupLot/filter' }),
    async fetchData() {
      this.itemGroups = await this.filterItemGroupLot({
        organizationId: ORGANIZATION_ID()
      })
    },
    filterItems(filter) {
      this.$emit('filter', filter)
      this.filterOn = true
      this.dialog = false
    },
    onReset() {
      this.filter = this.createFilterObj()
      this.$emit('filter', this.filter)
      this.filterOn = false
      this.dialog = false
    },
    createFilterObj() {
      return {
        specie: null,
        status: 'in_stock',
        woodCategory: null,
        itemGroup: null,
        name: null
      }
    }
  }
}
</script>
